import { ref, reactive } from 'vue';

import { MyMap, FileUpload, PlantSchedule, Gallery, LoadLayout, Review, PdfViewerPanel, VisualisePanel } from "#components";
import { v4 as uuidv4 } from "uuid";

export function useTools() {
  const uiStore = useUiStore()
  const { toggleChatDialog, togglePinChatDialog, toggleCommandDialog } = uiStore
  const { chatDialogOpen, chatDialogPinned } = storeToRefs(uiStore);

  const dockStore = useDockStore()
  const {addPendingPanel, addPanelState, setPanelId_toolUid } = dockStore;
  
  const tools = reactive([
    {
      uid: "28840ba0-1bd2-4ceb-93fa-322131ad1659",
      inUse: false,
      buttonType: "Button",
      description: "",
      apiRoute: "/api/tool/map",
      location: "toolbar",
      id: "map",
      label: "Map",
      tooltip: "Add Map",
      icon: "carbon:earth-filled",
      action: () => addMap(),
      component: null,
      componentName: "MyMap",
    },
    {
      uid: "49cff42f-c0a8-44cb-9f8a-85012507ba5e",
      inUse: false,
      buttonType: "Button",
      description: "",
      apiRoute: "/api/tool/optimise",
      location: "toolbar",
      id: "optimise",
      label: "Optimise",
      tooltip: "Optimise planting pallet",
      icon: "lucide:scatter-chart",
      action: () => {},
      component: null,
      componentName: "",
    },
    {
      uid: "5b1d91fd-39ae-4ff4-849e-2508541fb1e3",
      inUse: false,
      buttonType: "Button",
      description: "",
      apiRoute: "/api/tool/plants",
      location: "toolbar",
      id: "plants",
      label: "Plants",
      tooltip: "Add plants",
      icon: "ph:plant",
      action: () => {
        
        toggleCommandDialog(true)
      },
      component: null,
      componentName: "",
    },
    {
      uid: "4447bd6c-9ade-47c3-891b-2d88576a2ec7",
      inUse: false,
      buttonType: "Button",
      description: "Add your plant schedule",
      apiRoute: "/api/tool/parseSchedule",
      location: "toolbar",
      id: "schedule",
      label: "Schedule",
      tooltip: "Plant Schedule",
      icon: "material-symbols:rule-rounded",
      action: () => {},
      component: PlantSchedule,
      componentName: "PlantSchedule",
    },
    {
      uid: "f82b9927-7d8a-45e4-85df-1ced6b82af8b",
      inUse: chatDialogOpen.value,
      buttonType: "Toggle",
      description: "",
      apiRoute: "/api/tool/chat",
      location: "toolbar",
      id: "chat",
      label: "Chat",
      tooltip: "Chat",
      icon: "solar:chat-line-linear",
      component: null,
      componentName: "",
      action: () => {
        toggleChatDialog();
        tools.find((tool) => tool.id === "chat").inUse = chatDialogOpen.value;
      },
    },
    {
      uid: "392216f6-c82d-4950-81dc-3188cdaa69cc",
      inUse: false,
      buttonType: "Button",
      description: "",
      apiRoute: "/api/tool/visualise",
      location: "toolbar",
      id: "visualise",
      label: "Visualise",
      tooltip: "Visualise",
      icon: "uim:scenery",
      action: () => {},
      component: VisualisePanel,
      componentName: "VisualisePanel",
    },
    {
      uid: "1ee002ac-ba72-45be-9441-2cf2c7f9d22b",
      inUse: false,
      buttonType: "Button",
      description: "",
      apiRoute: "/api/tool/gallery",
      location: "toolbar",
      id: "gallery",
      label: "Image",
      tooltip: "Gallery",
      icon: "solar:gallery-wide-bold",
      action: () => {},
      component: Gallery,
      componentName: "Gallery",
    },
    {
      uid: "6a337643-2770-4cfe-8557-7c8409bdea7e",
      inUse: false,
      buttonType: "Button",
      description: "",
      apiRoute: "/api/tool/review",
      location: "toolbar",
      id: "review",
      label: "Review",
      tooltip: "Review your project",
      icon: "material-symbols-light:planner-review-rounded",
      action: () => {},
      component: Review,
      componentName: "Review",
    },

    {
      uid: "8b6ee2d8-ef11-462a-a6c8-b31b6bd21424",
      inUse: false,
      buttonType: "Button",
      description: "",
      apiRoute: "/api/tool/pdfviewer",
      location: "toolbar",
      id: "pdfviewer",
      label: "Pdf",
      tooltip: "View PDF",
      icon: "ph:file-pdf-light",
      action: () => {},
      component: PdfViewerPanel,
      componentName: "PdfViewerPanel",
    },
  ]);

  const docTypeToolMap: { [key: string]: string[] } = {
    'plantImage': ['gallery'],
    'PlantSchedule': [ 'visualise', 'map', 'schedule'],
    'report': ['schedule', 'pdfviewer'],
    'pdfviewer': ['pdfviewer'],
    'CAD': ['schedule'],
    'publication': ['schedule']

    // 'PlantSchedule': ['schedule', 'map', 'optimise', 'plants', 'chat', 'visualise'],
    // 'report': ['schedule', 'chat'],
    // 'CAD': ['map', 'visualise'],
    // 'publication': ['chat']
  }

  const initiateChat = () => {
    toggleChatDialog(true);
  }


  const addMap = () => {
    let uuid = uuidv4()
    console.log('adding map', uuid)
    
    addPanelState({
      [uuid]: {
        title: "Map",
        tooltip: `${uuid}`,
         data: {
          output: {center: [12.550343, 55.665957], zoom: 8} ,
         },
         tool_uid: '28840ba0-1bd2-4ceb-93fa-322131ad1659',
         iconSmall: 'lucide:map',
         closeType: 'unSave',
       },
     });

     setPanelId_toolUid({
       [uuid]: '28840ba0-1bd2-4ceb-93fa-322131ad1659',
     });
    
    addPendingPanel({
      panel_id: uuid,
      data: {
        output: {center: [12.550343, 55.665957], zoom: 8} ,
      },
      fileAnalysis: { output: {center: [12.550343, 55.665957], zoom: 8} , tool_uid: "28840ba0-1bd2-4ceb-93fa-322131ad1659" },
      analysisResult: { data: { output: {center: [12.550343, 55.665957], zoom: 8} }, tool_uid: "28840ba0-1bd2-4ceb-93fa-322131ad1659" },
      reuse: false,
    });
  }

  const inferTool = (fileMetadata: any) => {
    const extensionMap: { [key: string]: string[] } = {
      png: ["plantImage"],
      jpg: ["plantImage"],
      jpeg: ["plantImage"],
      xlsx: ["PlantSchedule"],
      doc: ["report"],
      pdf: ["PlantSchedule"],
    };

    const mimeTypeMap: { [key: string]: string[] } = {
      "image/": ["plantImage"],
      "text/": ["PlantSchedule"],
      "application/pdf": ["PlantSchedule"],
    };
    // TODO handle bad extensions
    const extension = fileMetadata.filename?.split(".").pop()?.toLowerCase();
    const toolsByExtension = extension && extensionMap[extension];
    
    const toolsByMimeType = Object.entries(mimeTypeMap)
      .filter(([key]) =>
        key.includes("/")
          ? fileMetadata.content_type === key
          : fileMetadata.content_type.startsWith(key)
      )
      .reduce((acc, [, value]) => acc.concat(value), []);

    const docTypes = toolsByExtension || (toolsByMimeType.length ? toolsByMimeType : ['defaultTool']);
    console.log('tools.ts: docTypes', docTypes);
    
    // use the docTypeToolMap to get the tool_uids and matchedTools
    const matchedTools = [...new Set(docTypes.flatMap((tool_id) => {
      const docTypes = docTypeToolMap[tool_id];
      return docTypes.map((docType) => tools.find((t) => t.id === docType));
    }))];
    
    const tool_uids = matchedTools.map(tool => tool?.uid);
    const tool_ids = matchedTools.map(tool => tool?.id);
    
    console.log('tools.ts: tool_uids', tool_uids);
    
    return { ...fileMetadata, tool_uids, tool_ids, tools, tool_objs: matchedTools };
  }

  const execTool = (opts: any, headers: any) => {
    console.log('executing', opts)
    // get tools from tool_uids
    const selectedTools = opts.tool_uids.map((tool_uid: any) => {
      return tools.find((tool: any) => tool.uid === tool_uid);
    });
    const promises = selectedTools.map((tool: any) => {      
      console.log('have uid?', tool.uid)
      if (tool?.apiRoute) {
        return $fetch(tool.apiRoute, {
          method: "POST",
          body: {
            ...opts.fileMetadata,
            inference: tool.uid,
            panel_id: opts.panel_id,
            tool_uid: tool.uid,
            ...opts,
          },
          headers,
        });
      }
      // else {
      //   return tool.action();
      // }
    }).filter(Boolean); // Filter out undefined values

    return promises;
  };

  return { tools, inferTool, docTypeToolMap, execTool };
}