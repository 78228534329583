<script setup lang="ts">
import PanelComponent from './PanelComponent.vue'
import { useScroll } from '@vueuse/core'

const uiStore = useUiStore()
const {windowHeight} = storeToRefs(uiStore)

const props = defineProps({
    panel: { type: Object, required: true },
    height: { type: [Number, String], required: false },
    panel_id: { type: String, required: true }
});

const pdfHeight = ref()

const isLoading = ref(true)

const pdfEmbed = ref(null)

const handleDocumentRender = () => {
  isLoading.value = false
}

const handlePasswordRequested = ({
  callback,
  isWrongPassword
}: {
  callback: (password: string) => void
  isWrongPassword: boolean
}) => {
  callback(prompt(isWrongPassword ?
    'Enter password again' :
    'Enter password'
  ) || '')
}

onMounted(() => {
  console.log(windowHeight.value)
  pdfHeight.value = props.height || windowHeight.value
  // pdfHeight.value = windowHeight.value
  console.log(pdfEmbed)
})

const el = ref<HTMLElement | null>(null)

const { arrivedState } = useScroll(el, {
  onScroll: (e) => {
    // Check if Ctrl key is pressed
    if (e.ctrlKey) {
      e.preventDefault() // Prevent default scroll behavior
      
      // Adjust scale based on scroll direction
      if (e.deltaY < 0) {
        pdfHeight.value = Math.min(pdfHeight.value + 0.1, 3) // Increase scale, max 3
      } else {
        pdfHeight.value = Math.max(pdfHeight.value - 0.1, 0.5) // Decrease scale, min 0.5
      }
    }
  },
})

</script>

<template>
  <PanelComponent :panel="props.panel" :height="props.height" :panel_id="props.panel_id"
     toolLabel="Pdf" dataKey="url">
    <template #default="{ data, tool_id, updateState }">
      <keep-alive>
        <div ref="el" class="overflow-hidden w-full h-full p-0 m-0 rounded-md p-2 pl-4 pb-10">
          <div class="flex justify-center align-center items-center w-full h-full">
            <ClientOnly>
              <VuePdfEmbed v-if="props.panel?.data?.output.url" ref="pdfEmbed" class="w-full h-full" text-layer 
                
                :scale="1"
                
                :source="{
                  url: props.panel?.data?.output.url, 
                  cMapUrl: 'https://unpkg.com/pdfjs-dist/cmaps/'
                }" 
              />
            </ClientOnly>
          </div>
        </div>
      </keep-alive>
    </template>
  </PanelComponent>
</template>

<style>
@import 'vue-pdf-embed/dist/styles/annotationLayer.css';
@import 'vue-pdf-embed/dist/styles/textLayer.css';

.vue-pdf-embed {
  /* width: 100%;
  height: 100%; */
}
</style>
