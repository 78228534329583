export const useChat = () => {

const chatLLM = async (prompt) => {
  try {
    const data = await $fetch("/api/ai/grq", {
      method: "POST",
      body: {
        prompt,
      },
    });
    // console.log('Generated topic name:', data.text)
    return data.text;
  } catch (error) {
    console.error("Error generating topic name:", error);
    return null;
    }
  };

  return {
    chatLLM,
  };

}