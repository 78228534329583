export const useValidation = () => {
    const validationStore = useValidationStore();

    const nameStatus: Ref<{
        exact: { original: string, matched: string }[],
        inexact: { original: string, matched: string }[],
        invalid: { original: string, matched: null }[]
    }> = ref({
        exact: [],
        inexact: [],
        invalid: []
    })

    const validateSpeciesNames = (namesToCheck: string[], gbif_names: string[]) => {
        for (let name of namesToCheck.filter(Boolean)) {
            if (name!=='undefined') {
                const lowercaseName = name.toLowerCase();
                const matchIndex = gbif_names.findIndex(gbif_name => 
                    gbif_name && gbif_name.toLowerCase() === lowercaseName
                );

                if (matchIndex !== -1) {
                    nameStatus.value.exact.push({ original: name, matched: gbif_names[matchIndex] });
                } else {
                    const partialMatchIndex = gbif_names.findIndex(gbif_name => 
                        gbif_name && gbif_name.toLowerCase().includes(lowercaseName)
                    );
                    if (partialMatchIndex !== -1) {
                        nameStatus.value.inexact.push({ original: name, matched: gbif_names[partialMatchIndex] });
                    } else {
                        nameStatus.value.invalid.push({ original: name, matched: null });
                    }
                }
            }
        }

        validationStore.addValidation({
            name: 'nameStatus',
            result: nameStatus.value,
            show: nameStatus.value.invalid.length != 0 || nameStatus.value.inexact.length != 0
        });
        
        return nameStatus.value;
    }

    return {
        validateSpeciesNames,
        nameStatus
    }
}
