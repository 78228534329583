<script setup>
import { useTools } from '@/composables/tools'
import {useChat} from '@/composables/chat'
import { theme } from "#tailwind-config";
import { watchDebounced, useElementBounding, useMagicKeys, whenever } from '@vueuse/core'
import { read, writeFileXLSX } from "xlsx";
import '~/assets/css/tabulator_style.css';
import { Loader2 } from 'lucide-vue-next'
import {
  HoverCard,
  HoverCardContent,
  HoverCardTrigger,
  
} from '@/components/ui/hover-card'
import { onClickOutside } from '@vueuse/core'
import { row } from '@unovis/ts/components/timeline/style';



// import { HoverCard, HoverCardContent, HoverCardTrigger, HoverCardPortal } from '@/components/ui/hover-card'        
// Store imports and setup
const projectStore = useProjectStore()
const { selectedProject } = storeToRefs(projectStore)
const dockStore = useDockStore();
const uiStore = useUiStore()

// Composables
const { tools } = useTools()
const { chatLLM } = useChat()
const colorMode = useColorMode();
const client = useSupabaseClient();

// Refs and computed properties
const plantscheduleRef = ref(null)
const { width: plantscheduleWidth, height: plantscheduleHeight, top: plantscheduleTop, left: plantscheduleLeft } = useElementBounding(plantscheduleRef)
const { windowWidth } = storeToRefs(uiStore)
const beamSize = computed(() => Math.floor(windowWidth.value * 0.5))
const loggingEnabled = ref(false)

// Store refs
const { dragOver, panelProps, includeDataInChat, activePanel, saving, panelState, panelToRefresh } = storeToRefs(dockStore);
const { setDragOver, setAppData, updatePanelState, mergeData, setActivePanel, addPanelState, refreshPanel, removePanelToRefresh } = dockStore;

const isMobile = computed(() => {
  return windowWidth.value <= 760;
});

const enableHovers = ref({
  row: false,
  column: isMobile.value ? false : true,
})

// Props and emits
const props = defineProps({
  panel: { type: Object, required: true },
  height: { type: [Number, String], required: false },
  panel_id: { type: String, required: true },
  fetchedPanelData: { type: Object, required: false },
  testMode: { type: Boolean, required: false }
});

const emit = defineEmits(['error'])


// State
const loading = ref(true);
const tabulator = ref(null)
const tables = ref(null)
const forceUseText = ref(null);
const title = ref('')
const inferredColumnTitles = ref(null)

const refreshedKey = ref(0)

const isEditingFalseOnMouseOut = ref(false)

// Computed properties
const tool_id = computed(() => tools.find(tool => tool.label === 'Schedule').uid)
const fileAnalysis = computed(() => {
  return props.panel?.data?.output?.[0]?.items || props.panel?.data?.output?.message?.[0]?.pages?.[0]?.items
})
const projectAnalysis = computed(() => {
  return props.panel?.data?.output?.tables
})

const metadata = ref(null)

// Methods
const log = (...args) => {
  if (loggingEnabled.value) {
    console.log(`[PlantSchedule.vue]`, ...args)
  }
}

const parseFileAnalysis = async (output) => {
  let useText = forceUseText.value;

  if (useText === null) {
    // Auto-detect if not forced
    const totalMdLength = output.reduce((sum, item) => sum + (item.md?.replace(/\s/g, '') || '').length, 0);
    const totalTextLength = output.reduce((sum, item) => sum + (item.text?.replace(/\s/g, '') || '').length, 0);
    useText = totalTextLength > totalMdLength * 1.2;
  }

  const flattenedArray = output.flatMap(item => useText ? item.text : item.md).filter(Boolean);

  let md_items = flattenedArray.join('\n\n');
  let { data: cached_grq_js } = useNuxtData(`grq-js-${props.panel_id}`)

  if (cached_grq_js.value?.data?.tables) {
    console.log('using cached grq response')
    return toRaw(cached_grq_js.value.data.tables);
  }
  // let res = ref({data: {tables: []}})
  console.log('getting grq response')
  loading.value = true
  let { data: res } = await useAsyncData(
    `grq-js-${props.panel_id}`, // Unique key for this request
    () => $fetch('/api/ai/grq-js', {
      method: 'POST',
      body: {
        prompt: md_items
      }
    })
  );
  loading.value = false
  return formatForTabulator(toRaw(res?.value?.data?.tables));
}

const getTables = async () => {
  if (projectAnalysis.value) {
    console.log('using cached project analysis', projectAnalysis.value)
    return toRaw(projectAnalysis.value);
  }
  const output = fileAnalysis.value
  console.log('output', output)
  return await parseFileAnalysis(output)
}

const formatForTabulator = (parsedTables) => {
  if (parsedTables) {
    console.log('formatting for tabulator')
    const columns = parsedTables[0].columns.map((col, index) => ({
      title: col,
      field: `col${index}`,
      headerWordWrap: true,
      headerSort: true,
      // headerHozAlign: "center",
      // hozAlign: "left",
      editor: "input",
      resizable: "header",
      headerVertical: false,
      formatter: function (cell, formatterParams) {
        const value = cell.getValue();
        if (typeof value === 'number') {
          return value.toLocaleString('en-US', { maximumFractionDigits: 2 });
        }
        return value;
      },
    }));

    const rows = parsedTables.flatMap((table, tableIndex) =>
      table.rows.map((row, rowIndex) => {
        const rowData = {
          id: `${tableIndex}-${rowIndex}`,
          _group: table.group_id || undefined,
          _notes: table.notes?.join(', ') || undefined
        };
        table.columns.forEach((col, colIndex) => {
          const value = row[colIndex];
          rowData[`col${colIndex}`] = !isNaN(parseFloat(value))
            ? parseFloat(value)
            : value;
        });
        return rowData;
      })
    );

    return { columns, rows };
  }
  console.log('could not get tabulator data')
  return null;
};

const downloadAs = (type) => {
  console.log('downloading', tabulator)
  try {
    tabulator.value.download({
      filename: `${selectedProject.value.title} (${panelState?.[panel_id]?.fileMetadata?.filename}).${type}`,
      sheetName: selectedProject.value.title,
      fileType: type
    });
  } catch (e) {
    console.error('error downloading', e)
  }
}

const handleClick = () => {
  console.log('clicked', props.panel.panel.name)
  setActivePanel(props.panel.panel.name)
}





// Use projectData composable
const projectData = useProjectData();
const { updatePanelStateOutput, updatedAndSavedPanel, setupPanelWatchers } = projectData;

// Setup watchers

watch(panelToRefresh, (newVal) => {
  if(newVal.includes('PlantSchedule')){
    refreshedKey.value++
    removePanelToRefresh('PlantSchedule')
  }
})

if(!props.testMode){
  const updateState = setupPanelWatchers(props.panel, props.panel_id, tool_id.value, tables, 'tables', saving, metadata)
  
  watch(() => updateState(), (newState) => {
    if (newState) {
      addPanelState(newState)
  }
}, { deep: true })

}
watch(tables, async (newTables) => {
  if (newTables == undefined) {
    push.error({ title: 'Error', message: "Couldn't read the plant schedule. Try again with a different file" })
  } else {
    loading.value = false
  }
})

const { ctrl_z, escape } = useMagicKeys()

watch(ctrl_z, (pressed) => {
  if (pressed && tabulator.value) {
    try {
      tabulator.value.undo()
    } catch (e) {
      console.warn("can't undo", e)
    }
  }
})

watch(escape, (pressed) => {
  if (pressed) {
    try {
      isEditing.value = false
      showColumnStats.value = false
      showRowStats.value = false
    } catch (e) {
      console.warn("can't undo", e)
    }
  }
})

const colDiv = reactive({
  show: false,
  top: 0,
  left: 0,
  width: 0,
  height: 0
})

const cellDiv = reactive({
  show: false,
  top: 0,
  left: 0,
  width: 0,
  height: 0
})

const rowDiv = reactive({
  show: false, 
  top: 0,
  left: 0,
  width: 0,
  height: 0
})

const handleRowMouseOver = ({ event, rowData, boundingBox }) => {
}

const handleHeaderMouseOver = ({ event, column }) => {
  // console.log('header mouse over', event, column)
}

const scheduleOffsets = computed(() => {
  return {
    top: plantscheduleRef.value.getBoundingClientRect().top,
    left: plantscheduleRef.value.getBoundingClientRect().left,
  width: plantscheduleRef.value.getBoundingClientRect().width,
    height: plantscheduleRef.value.getBoundingClientRect().height
  }
})
const plantscheduleHeader = ref(null)

const tableHeight = ref(0)

const tableRect = ref(null)

const headerHeight = ref(null)
const cellData = ref(null)

const showColumnStats = ref(true)
const showRowStats = ref(true)
const columnStats = ref(null)
const columnStatsHeight = ref(0)
const lastCol = ref(false)
const isEditing = ref(false)

const columnData = ref(null)
const rowData = ref(null)

const columnTitle = ref('')


const handleCellMouseOver = ({ event, cell, boundingBox }) => {
  if (isEditing.value) {
    return
  }
  cellData.value = cell._cell.value
  rowData.value = cell._cell.row.getData()
  columnData.value = cell._cell.column.cells
    .map(cell => cell.value)
    .filter(value => value !== null && value !== undefined && value !== '');
  columnTitle.value = cell._cell.column?.definition?.title || ''
  
  
  let colElement = cell._cell.column.element.getBoundingClientRect()
  let firstCell = cell._cell.column.cells[0].getElement().getBoundingClientRect()
  let lastCell = cell._cell.column.cells[cell._cell.column.cells.length - 1].getElement().getBoundingClientRect()
  // console.log('lastCell', cell._cell.column.cells[cell._cell.column.cells.length - 1])
  let plantscheduleRefRect = plantscheduleRef.value.getBoundingClientRect()
  let cellElement = cell.getElement().getBoundingClientRect()
  let rowElement = cell._cell.row.getElement().getBoundingClientRect()
  let headerElement = cell._cell.column.parent.headersElement.getBoundingClientRect()
  
  headerHeight.value = headerElement.value?.height
  
  let plantscheduleHeaderElement = plantscheduleHeader.value.getBoundingClientRect()

  if (!cell._cell.column.field) {
    cell.getElement().classList.remove('hover')
    showColumnStats.value = false
  } else{
    showColumnStats.value = true
  }
    
  if (enableHovers.value.column) {
    
      // loop over all cells and add a class to all of them
      cell._cell.column.cells.forEach((cell, index) => {
          cell.getElement().classList.add('hover')
      })
      
    
  }
  if (enableHovers.value.row) {
    // console.log(cell._cell.row)
    cell._cell.row.cells.forEach(cell => {
      cell.getElement().classList.add('hover')
    })
  }

  let convexBoundingRect = {
    x: lastCell.x,
    y: colElement.y,
    width: lastCell.x + lastCell.width - firstCell.x,
    height: ((lastCell.y) - (colElement.y))
  }


  let cellBoundingRect = {
    x: cellElement.x,
    y: cellElement.y,
    width: cellElement.width,
    height: cellElement.height
  }

  
  let hardCodedOffsets = {
    top: (-67 - headerElement.height),
    left: 5.5,
    width: 0,
    height: 0 + headerElement.height
  }

  let groupElement = cell._cell.column.groupElement.getBoundingClientRect()

  colDiv.show = true
  colDiv.top = 0
  colDiv.left = convexBoundingRect?.x + hardCodedOffsets.left - plantscheduleLeft.value + hardCodedOffsets.left
  colDiv.width = cellBoundingRect?.width + hardCodedOffsets.width
  colDiv.height = ((cell._cell.row.parent.displayRowsCount) * cell._cell.height)  
  

  cellDiv.show = true
  cellDiv.top = cellBoundingRect.y + hardCodedOffsets.top
  cellDiv.left = cell._cell.element.x + hardCodedOffsets.left
  cellDiv.width = cellBoundingRect.width + hardCodedOffsets.width
  cellDiv.height = groupElement.height

  rowDiv.show = true
  rowDiv.left = 0
  rowDiv.width = plantscheduleRefRect.width
  rowDiv.height = rowElement.height
  rowDiv.top = rowElement.top - scheduleOffsets.value.top + plantscheduleHeaderElement.height + 10
  rowDiv.bottom = rowElement.bottom

  
} 





watch(columnStats, (newVal) => {
  if(newVal){
    columnStatsHeight.value = newVal.getBoundingClientRect().height
  }
})

onClickOutside(columnStats, () => {
  if (isEditing.value) {
    // showColumnStats.value = false
    // showRowStats.value = false
  }
}, { ignore: ['.tabulator-cell', '.tabulator-table'] })


onClickOutside(plantscheduleRef, () => {
  if (isEditing.value) return
  showColumnStats.value = false
  showRowStats.value = false
}, { ignore: ['.tabulator-cell'] })

const handleTableMouseLeave = () => {
  // colDiv.show = false
}

const handleGroupMouseMove = ({ e, group }) => {
  if (isEditing.value) return
  showColumnStats.value = false
  showRowStats.value = false
}

const handleCellMouseLeave = ({ e, cell }) => {
  // loop over all cells and remove a class to all of them
  if (enableHovers.value.column) {
    cell._cell.column.cells.forEach(cell => {
      cell.getElement().classList.remove('hover')
    })
  }
  if(enableHovers.value.row){
    cell._cell.row.cells.forEach(cell => {
      cell.getElement().classList.remove('hover')
    })
  }
}


const handleCellEditing = ({ e, cell }) => {
// add class to cell
  // make cell width fit content
  // cell._cell.column.setWidth('300px')
  // cell._cell.table.redraw()
  // check what index cell is in cell._cell.row.cells
  
  lastCol.value = cell._cell.column.parent.columnsByIndex.indexOf(cell._cell.column) == cell._cell.column.parent.columnsByIndex.length - 1
  cellData.value = cell._cell.value

  cell._cell.table.element.classList.add('table-editing')
  cell._cell.getElement().classList.add('cell-editing')
  isEditing.value = true
  // Move caret to the start of the input
  nextTick(() => {
    const input = cell.getElement().querySelector('input')
    if (input) {
      input.select()
      // input.setSelectionRange(0, 0)
      input.setAttribute('spellcheck', 'false')
    }
  })
}

const handleCellEditCancelled = ({ e, cell }) => {
  
  cell._cell.table.element.classList.remove('table-editing')
  cell._cell.getElement().classList.remove('cell-editing')
  isEditingFalseOnMouseOut.value = true
  // isEditing.value = false
}

const handleCellEdited = ({ e, cell }) => {
  cell._cell.table.element.classList.remove('table-editing')
  cell._cell.getElement().classList.remove('cell-editing')
  // showColumnStats.value = false
  isEditing.value = false
  //redraw the column
  // cell._cell.column.redraw()
}

// watchDebounced(plantscheduleWidth, (newWidth) => {
// watchDebounced(plantscheduleWidth, (newWidth) => {
//   if(tables.value?.columns){
//     tables.value.columns = newWidth < 640
//   }
// }, { immediate: true, debounce: 50 })




const handleRowAdded = (rowData) => {
  console.log('New row added:', rowData);
};


// Use useMagicKeys to detect spacebar press
const { space } = useMagicKeys()

// Hide stats when spacebar is pressed
whenever(space, () => {
  // prevent default
     
  showColumnStats.value = false
  showRowStats.value = false
})


const inferColumnTypes = async () => {
  let oldTitles = JSON.stringify(tables.value.columns.map(column => column.title))
  try {
    const response = await chatLLM(`You are an expert landscape architect, Give the following list of plant schedule table column headings, match them with their canonical labels. You must only respond in json by mapping each given column title as key, and value set to the closest matching canonical value, leave empty if none found. duplicate columns and values are allowed. Do not respond with any other text just speak json. List of canonical values: ['qty', 'botanicalname', 'commonname', 'potsize', 'spacing', 'density', 'code', 'width', 'height', 'spread', 'proportionpercentage'] ${oldTitles}`)

    // Clean up the response
    const cleanedResponse = response
      .replace(/\n/g, '') // Remove newlines
      .replace(/\\/g, '') // Remove backslashes
      .replace(/^"|"$/g, '') // Remove leading and trailing quotes if present

    return JSON.parse(cleanedResponse)
  } catch (error) {
    console.error('Error inferring column types', error)
    return {} // Return an empty object in case of error
  }
}


const getMetadata = async () => {
  const metadata = props.panel?.data?.output?.metadata || {};
  const result = { ...metadata };

  if (!result.title) {
    let commonNames = JSON.stringify(tables.value.rows.map(row => row.col2));
    result.title = await chatLLM(`You are an expert landscape architect. Given the following list of common name plants from planting pallet, think of the most relevant but extremely specific documentation package title or project name. Something sensible, not too much flourish or creativity. You must provide two or three word title and say nothing other than those words in your response (stripped of quotation marks, hyphens). Pallet: ${commonNames}`);
  }

  if (!result.inferredColumnTitles) {
    result.inferredColumnTitles = await inferColumnTypes();
  }

  if (!result.fileMetadata) {
    // console.log('do I have panelstate?', panelState?.[props.panel_id])
    result.fileMetadata = props?.panel?.data?.fileMetadata
  }

  // Assuming fileMetadata is set elsewhere or not needed to be generated here

  return result;
}

// Initial data fetch
onMounted(async () => {
  
  
  tables.value = await getTables()
  metadata.value = await getMetadata()
    // TODO Llm OUTPUT 
  // tables.value.llm_output = await chatLLM()
  // tables.value.fileMetadata = await chatLLM()

})






// Content type mapping
const contentTypeToExtension = ref({
  'application/pdf': { label: 'PDF', iconLarge: 'vscode-icons:file-type-pdf2', iconSmall: 'ph:file-pdf-fill' },
  'application/msword': { label: 'Word Document', iconLarge: 'vscode-icons:file-type-word', iconSmall: '' },
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': { label: 'Word Document', iconLarge: 'vscode-icons:file-type-word', iconSmall: '' },
  'application/vnd.ms-excel': { label: 'Excel Document', iconLarge: 'vscode-icons:file-type-excel', iconSmall: '' },
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': { label: 'Excel Document', iconLarge: 'vscode-icons:file-type-powerpoint', iconSmall: '' },
  'application/vnd.ms-powerpoint': { label: 'PowerPoint', iconLarge: 'vscode-icons:file-type-powerpoint', iconSmall: '' },
  'application/vnd.openxmlformats-officedocument.presentationml.presentation': { label: 'PowerPoint', iconLarge: 'vscode-icons:file-type-powerpoint', iconSmall: '' },
  'text/plain': { label: 'Text file', iconLarge: 'hugeicons:txt-02', iconSmall: '' },
})

const editing = ref(false)

const handleUpdateTitle = async (newTitle) => {
  metadata.value.title = newTitle
  // You might want to add logic here to update the title in your backend or store
  editing.value = false
}


const handleMouseLeaveColumnStats = () => {
  if (isEditingFalseOnMouseOut.value) {
    isEditing.value = false
    isEditingFalseOnMouseOut.value = false
    showColumnStats.value = false
    showRowStats.value = false
  }
}

const handleMouseLeaveRowStats = () => {
  if (isEditingFalseOnMouseOut.value) {
    isEditing.value = false
    isEditingFalseOnMouseOut.value = false
    showColumnStats.value = false
    showRowStats.value = false
  }
}

const addRow = () => {
  if (tabulator.value) {
    tabulator.value.addNewRow(); // Add to top of table
  }
};

</script>

<template>
  <div ref="plantscheduleRef"
    class="w-full h-[95%]  flex flex-col justify-start items-start overflow-hidden pr-1 sm:pr-4 pt-0 mt-0 align-start mr-[-5px] sm:pl-2 mt-2">

    <div v-if="loading" class="absolute z-10 w-full h-full flex pointer-events-none justify-center ">
      <Loader2 class="w-8 h-8 mr-2 animate-spin self-center" />
    </div>

    <div ref="plantscheduleHeader" class="w-full h-12 sm:h-24 flex justify-between items-start align-start">
      <div class="flex flex-col items-start w-full rounded-md h-16">

        <h3 v-if="metadata" class="text-lg font-bold">
          <InlineEdit v-if="editing" :modelValue="metadata?.title" @update:modelValue="handleUpdateTitle"
            @edit="editing = $event" :scale="1.2" preview-class="text-muted-foreground"
            input-class="pointer-events-auto" :buttons-width="90" save-icon="lucide:check" cancel-icon="lucide:x"
            :maxInputWidth="300" />
          <span v-else @click="editing = true" class="cursor-pointer">
            {{ metadata?.title }}
          </span>
        </h3>
        <p v-if="metadata?.fileMetadata?.filename"
          class="w-full text-xs font-semibold text-muted-foreground space-x-2 py-1">
          <Icon :name="contentTypeToExtension[metadata?.fileMetadata?.content_type]?.iconSmall" class="w-4 h-4" />
          <span class="bg-muted p-1 rounded-md">{{ metadata?.fileMetadata?.filename }}</span>
        </p>
      </div>
      <div class="flex items-center justify-end w-1/2 gap-2 opacity-75 scale-75 origin-top-right">
        <CycleButton v-model:enableHovers="enableHovers" />
        <TooltipProvider :delayDuration="200" :skipDelayDuration="700">
          <Tooltip>
            <TooltipTrigger>
              <Button v-if="tables" class="p-2 aspect-square" variant="outline" @click="downloadAs('xlsx')">
                <Icon name="mdi:google-spreadsheet" class="w-6 h-6 p-0" />
              </Button>
            </TooltipTrigger>
            <TooltipContent
              class="pointer-events-all bg-primary text-background shadow-xxl !animate-none select-none border-none">
              <p>Download as XLSX</p>
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>

      </div>
    </div>

    <ClientOnly>

      <HoverCard v-if="colDiv && enableHovers.column && columnData" :open="showColumnStats" :defaultOpen="false"
        :openDelay="0" :closeDelay="0">
        <HoverCardTrigger as-child>
          <div class="absolute flex flex-row pointer-events-none" :style="{
            top: `${colDiv.top}px`,
            left: `${colDiv.left}px`,
            width: `${colDiv.width}px`,
            height: `${colDiv.height}px`
          }">
            <!-- <PlantScheduleColumnStats :width="colDiv.width" :overflowing="colDiv.overflowing" :tableRect="tableRect" :colRect="colDiv" :panelEdgeX="{right: plantscheduleRef.getBoundingClientRect().right, left: plantscheduleRef.getBoundingClientRect().left}" />   -->
          </div>
          <!-- Your trigger element here -->
        </HoverCardTrigger>

        <HoverCardContent :avoidCollisions="true" :collisionBoundary="plantscheduleRef" :prioritizePosition="false"
          sticky="always" updatePositionStrategy="always" align="start" side="right"
          :collisionPadding="{ top: 150, bottom: 0 }" :alignOffset="0" :sideOffset="0" :hideWhenDetached="true"
          class="w-[30vw] h-fit bg-background HoverCardContent flex transition-all "
          :class="{ 'tabulator-editing ml-[120px]': isEditing }">
          <div ref="columnStats" @mouseleave="handleMouseLeaveColumnStats" :style="{ paddingBottom: '20px' }" o
            class="border-primary/60 border-2 w-full h-full opacity-[0.8] rounded-lg row-stats ">

            <ColumnStatsPanel :metadata="metadata" :title="columnTitle" :col="columnData" :row="rowData"
              :cell="cellData" />


            <!-- <div v-if="true" class="w-64 absolute top-0 -left-128 bg-background"
              :style="{ height: colDiv.height + 'px', maxHeight: 'var(--radix-hover-card-content-available-height)' }">
              asdf
            </div> -->
          </div>
        </HoverCardContent>


      </HoverCard>

      <HoverCard v-if="rowDiv && enableHovers.row && columnData" :open="showRowStats" :defaultOpen="false"
        :openDelay="0" :closeDelay="0">
        <HoverCardTrigger as-child>
          <div class="absolute flex flex-row pointer-events-none h-0" :style="{
            top: `${rowDiv.top}px`,
            left: `${rowDiv.left}px`,
            width: `${plantscheduleWidth}px`,
            height: `${rowDiv.height}px`
          }">
            <!-- <PlantScheduleColumnStats :width="colDiv.width" :overflowing="colDiv.overflowing" :tableRect="tableRect" :colRect="colDiv" :panelEdgeX="{right: plantscheduleRef.getBoundingClientRect().right, left: plantscheduleRef.getBoundingClientRect().left}" />   -->
          </div>
          <!-- Your trigger element here -->
        </HoverCardTrigger>

        <HoverCardContent :avoidCollisions="true" :collisionBoundary="plantscheduleRef" :prioritizePosition="false"
          sticky="always" updatePositionStrategy="always" align="center" side="bottom" :collisionPadding="0"
          :alignOffset="0" :sideOffset="0" :hideWhenDetached="true"
          class="w-fit h-[100vh] bg-transparent HoverCardContent pointer-events-none flex transition-all py-0 my-0"
          :class="{ 'tabulator-editing mt-8': isEditing }">
          <div ref="rowStats" @mouseleave="handleMouseLeaveRowStats"
            :style="{ paddingBottom: '20px', width: `${plantscheduleWidth-10}px` }"
            class="w-full bg-transparent h-fit pointer-events-none opacity-[0.8] row-stats ">
            <RowStatsPanel :col="columnData" :row="rowData" :cell="cellData" />



          </div>
        </HoverCardContent>


      </HoverCard>
      <div ref="plantscheduleRef" v-if="tables" :key="refreshedKey" class="w-full h-full align-start items-start">

        <TabulatorTable v-if="tables.rows" ref="tabulator" :key="refreshedKey" @click="handleClick" :rows="tables.rows"
          class="is-bordered is-narrow overflow-hidden" :movableColumns="true" :columns="tables.columns" :options="{
            groupBy: '_group',
            height:'100%',
            layout: 'fitDataFill',
            selectableRows: true,
            selectableRowsRangeMode: 'click',
            history: true,
            movableColumns: true,
            responsiveLayout: 'collapse'
          }" @row-mouseover="handleRowMouseOver" @headerMouseOver="handleHeaderMouseOver"
          @cellMouseOver="handleCellMouseOver" @table-height="tableHeight=$event" @table-rect="tableRect=$event"
          @groupMouseMove="handleGroupMouseMove" @cellMouseLeave="handleCellMouseLeave" @cellEditing="handleCellEditing"
          @cellEditCancelled="handleCellEditCancelled" @cellEdited="handleCellEdited" @row-added="handleRowAdded" />
          <!-- <Button @click="addRow">Add Row</Button> -->



      </div>
      <div v-if="!tables && !loading" class="flex flex-col justify-center items-center space-y-2">
        <Icon name="solar:sad-square-outline" class="w-10 h-10" />
        <div class="text-sm w-[600px]">No data available. Please check your data source.</div>
      </div>


      <BorderBeam class="pointer-events-none" v-if="includeDataInChat && activePanel === panel_id" borderRadius="10px" :anchor="90"
        :size="beamSize" />

      <!-- <div v-if="showColumnStats" v-motion-fade
        class="absolute column-stats z-[1000] flex flex-row bg-accent mix-blend-multiply rounded-xl pointer-events-none constrainHeightToPopover"
        :style="{
        top: `${colDiv.top}px`,
        left: `${colDiv.left}px`,
        width: `${colDiv.width}px`,
        height: `${colDiv.height}px`
      }"></div> -->
    </ClientOnly>
  </div>

  <div :class="`absolute -bottom-4 left-0 bg-transparent w-full h-[${plantscheduleHeight * .1}px]`">
    <Accordion type="single" collapsible class="no-chevron">
      <AccordionItem value="debug">
        <AccordionTrigger class="max-w-fit hover:no-underline">
          <Icon name="solar:code-bold-duotone" class="w-4 h-4 mx-2 mb-2 bg-background rounded-full" />
          <span class="text-xs mb-1 text-primary/20">{{ panel_id }}</span>
        </AccordionTrigger>
        <AccordionContent>
          <ScrollArea :class="`w-full bg-muted p-10 h-[80vh] overflow-hidden`">
            <pre class="text-xs text-wrap">{{ panel?.data?.output }}</pre>
            <pre class="text-xs text-wrap">{{ tables }}</pre>
          </ScrollArea>
        </AccordionContent>
      </AccordionItem>
    </Accordion>
  </div>

</template>

<style lang="scss">
.tabulator.is-bordered {
  border: none !important;
}



.tabulator-editing .tabulator-cell:not(.cell-editing) {
  opacity: 0.3 !important;
  pointer-events: none;
}
.tabulator-editing .tabulator-cell:not(.cell-editing) > *:not(.HoverCardContent) {
  pointer-events: none;
}

.cell-editing {
  opacity: 1 !important;
  // padding: 3px;
  // z-index: 1000 !important;
  width: 130px !important;
  box-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  // border: 2px solid hsl(var(--primary)) !important;
  box-sizing: content-box !important;
  border-radius: 5px;
  background-color: hsl(var(--background));
  overflow: visible !important;
  pointer-events: all !important;
  transform: scale(1.25);
  transform-origin: center left;
  margin: 0 10px !important;
  z-index: 1000 !important;

  &::after {
      content: '';
      position: absolute;
      top: -2px;
      left: -2px;
      right: -2px;
      bottom: -2px;
      border: 1px solid hsl(var(--primary));
      border-radius: 5px;
      // pointer-events: none;
    }
  // box-shadow:
  //   1.7px 1.5px 2.4px rgba(0, 0, 0, 0.073),
  //   4px 3.6px 5.8px rgba(0, 0, 0, 0.105),
  //   7.5px 6.8px 10.9px rgba(0, 0, 0, 0.13),
  //   13.4px 12.1px 19.4px rgba(0, 0, 0, 0.155),
  //   25.1px 22.6px 36.3px rgba(0, 0, 0, 0.187),
  //   60px 54px 87px rgba(0, 0, 0, 0.26);
}

.cell-editing * {
  opacity: 1 !important;
}

/* Ensure HoverCard and its content remain fully opaque */
.HoverCardContent,
.HoverCardContent * {
  box-shadow: none !important;
  opacity: 1 !important;
}

@import '~/assets/css/tabulator_style.css';

.no-chevron svg {
  display: none;
}

.constrainHeightToPopover{
  max-height: var(--radix-hover-card-content-available-height) !important;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: scale(1) !important;
  }
  to {
    opacity: 1;
    transform: scale(1) !important;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.HoverCardContent {
  animation: fadeIn 80ms ease-out !important;
  border: none !important;
  padding: 0 !important;
}

.HoverCardContent[data-state="closed"] {
  animation: fadeOut 180ms ease-out !important;
}

.cell-editing{
  box-shadow:
      1.7px 1.5px 2.4px rgba(0, 0, 0, 0.073),
      4px 3.6px 5.8px rgba(0, 0, 0, 0.105),
      7.5px 6.8px 10.9px rgba(0, 0, 0, 0.13),
      13.4px 12.1px 19.4px rgba(0, 0, 0, 0.155),
      25.1px 22.6px 36.3px rgba(0, 0, 0, 0.187),
      60px 54px 87px rgba(0, 0, 0, 0.26);
}

.column-stats, .row-stats{
  animation: fadeIn 0.5s ease-out !important;
    // box-shadow: 7.4px 0px 3.2px -8px rgba(0, 0, 0, 0.036),
    //   -7.4px 0px 3.2px -8px rgba(0, 0, 0, 0.036),
    //   17.2px 0px 7.5px -8px rgba(0, 0, 0, 0.052),
    //   -17.2px 0px 7.5px -8px rgba(0, 0, 0, 0.052),
    //   30.9px 0px 13.4px -8px rgba(0, 0, 0, 0.064),
    //   -30.9px 0px 13.4px -8px rgba(0, 0, 0, 0.064),
    //   51.2px 0px 22.3px -8px rgba(0, 0, 0, 0.076),
    //   -51.2px 0px 22.3px -8px rgba(0, 0, 0, 0.076),
    //   84.4px 0px 36.8px -8px rgba(0, 0, 0, 0.088),
    //   -84.4px 0px 36.8px -8px rgba(0, 0, 0, 0.088),
    //   147.5px 0px 64.3px -8px rgba(0, 0, 0, 0.104),
    //   -147.5px 0px 64.3px -8px rgba(0, 0, 0, 0.104),
    //   319px 0px 139px -8px rgba(0, 0, 0, 0.14),
    //   -319px 0px 139px -8px rgba(0, 0, 0, 0.14);
}


div:has(div.HoverCardContent:not(.tabulator-editing)){
  pointer-events: none !important;
}
div:has(div.HoverCardContent.tabulator-editing){
  pointer-events: all !important;
}



.tabulator-cell.hover:not(.cell-editing){
  background-color:  hsl(var(--muted-foreground));
  color: hsl(var(--background));
  border: none !important;
}
.tabulator-cell.disable-hover{
  background-color:  unset !important;
  color: unset !important;
  border: none !important;
}


</style>