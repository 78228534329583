<script setup lang="ts">
const { search } = useMeiliSearch('gbif')
import { useGeolocation } from '@vueuse/core'
import { Loader2 } from 'lucide-vue-next'
import { Accordion, AccordionItem, AccordionTrigger, AccordionContent } from '@/components/ui/accordion'
const { coords } = useGeolocation()

const dockStore = useDockStore()
const { activePanel } = storeToRefs(dockStore)

const { validateSpeciesNames, nameStatus } = useValidation()
const { fetchSpeciesDataIndividually, results, status } = useSearch()



const {data, metadata, cell} = defineProps<{
  data: string[],
  metadata: any,
  cell?: any
}>()

const emit = defineEmits(['results'])

const showHide = ref({
  exact: true,
  inexact: true,
  invalid: true
})




const refresh = async () => {
  const names = await fetchSpeciesDataIndividually(data, {coords: coords.value})
  if (names.length === 0) {
    return
  }
  const nameStatus = validateSpeciesNames(names.map(n => String(n?.[0]?.species)), data.map(n => String(n)))
  emit('results', nameStatus)
  console.log(nameStatus.exact)
  showHide.value.exact = nameStatus.exact.length > 0
  showHide.value.inexact = nameStatus.inexact.length > 0
  showHide.value.invalid = nameStatus.invalid.length > 0
  
}

onMounted(refresh)

const colorMode = useColorMode()

</script>

<template>
  <div>
    <img v-if="colorMode.value == 'dark'" src="~/public/gbif-dot-org-white-logo.svg"
      class="w-18 h-10 inline-block brightness-[0.8]" />
    <img v-else src="~/public/gbif-dot-org-green-logo.svg" class="w-18 h-10 inline-block " />

    <div v-if="results.length > 0" class="flex flex-col items-start mb-2">
      <div class="flex flex-row items-center gap-1 ml-2">
        <Loader2 class="w-4 h-4 animate-spin" v-if="status === 'pending'" />
        <Icon class="w-5 h-5" name="lets-icons:done-all-round-light"
          v-if="status === 'idle' && nameStatus.exact.length === data.length" />
        <Icon class="w-5 h-5" name="lets-icons:done-all-round-duotone"
          v-if="status === 'idle' && nameStatus.exact.length !== data.length" />
        <p v-if="status === 'pending'" class="text-sm text-muted-foreground">
          Validating species names against GBIF
        </p>
        <p v-if="status === 'idle'" class="text-xs text-muted-foreground">
          Species names check <span class="font-semibold">{{ nameStatus.exact.length }} exact, {{
            nameStatus.inexact.length }} partial, and {{ nameStatus.invalid.length
            }} invalid.</span>
        </p>
      </div>



      <div class="flex flex-col gap-2 w-full mt-2">
        <Accordion type="multiple" :default-value="['partial-match', 'invalid']" class="space-y-1">
          <AccordionItem v-for="(names, type) in { 
            'exact-match': nameStatus.exact, 
            'partial-match': nameStatus.inexact, 
            'invalid': nameStatus.invalid 
          }" :key="type" :value="type" class="border rounded-sm">
            <AccordionTrigger class="px-3 py-2 text-sm hover:no-underline hover:bg-muted/50">
              <h3 class="font-semibold flex flex-row items-center gap-1">
                {{ type.replace('-', ' ').replace(/\b\w/g, l => l.toUpperCase()) }}
                <Badge
                  class="select-none bg-muted-foreground/20 hover:text-background text-muted-foreground text-xs py-0 px-1 font-normal whitespace-nowrap">
                  {{ names.length }}</Badge>
              </h3>
            </AccordionTrigger>
            <AccordionContent class="px-3 py-1">
              <div v-if="type === 'exact-match'" class="flex flex-row flex-wrap gap-1">
                <Badge v-for="item in names" :key="item.original"
                  variant="default"
                  :class="[
                    'select-none text-xs py-0 px-2 font-normal whitespace-nowrap',
                    { 'font-bold border-2 border-primary shadow-sm': item.matched === cell }
                  ]">
                  {{ item.original }}
                </Badge>
              </div>
              <div v-else class="flex flex-col gap-1">
                <div v-for="item in names" :key="item.original" 
                     :class="[
                       'flex items-center gap-2']">
                  <Badge
                    :variant="type === 'partial-match' ? 'secondary' : 'destructive'"
                    class="select-none text-xs py-0 px-2 font-normal whitespace-nowrap" :class="{ 'bg-primary text-background': item.matched === cell }">
                    {{ item.original }}
                  </Badge>
                  <span class="text-xs border-2 border-transparent text-muted-foreground whitespace-nowrap truncate" :class="{ 'bg-background border-2 border-destructive': item.matched === cell }">
                    ({{ item.matched || 'No match' }})
                  </span>
                </div>
              </div>
            </AccordionContent>
          </AccordionItem>
        </Accordion>
      </div>

    </div>
    <div class="flex flex-row flex-wrap gap-1">
      <!-- <div class="" v-if="status === 'idle' && results.length > 0" v-for="r in results" :key="r[0].species"> -->

      <!-- <Badge v-if="r?.[0]?.species.trim() !== ''">{{ r?.[0]?.species}}</Badge>
        <Badge v-else color="destructive" class="text-muted-foreground">
          {{ r?.[0]?.name }}
        </Badge> -->
      <!-- </div> -->
    </div>
  </div>
</template>

<style scoped>
.accordion-content {
  padding: 0.25rem 0.75rem;
}
</style>