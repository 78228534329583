<script setup lang="ts">

const props = defineProps<{
  col: any
  row: any
  cell: any
  title: string
  metadata: any
}>()

const uniqueInCol = computed(() => {
  return [...new Set(props.col)]
})

const shouldRenderBar = computed(() => {
  return !!props.col && !!props.cell;
})

onMounted(() => {
  // console.log('propsmounted', props)
})


</script>

<template>
  <div class="w-full h-fit flex flex-col p-3 text-muted-foreground">
    <div v-if="uniqueInCol" class="text-4xl font-extrabold mb-6 flex items-center">
      <div
        class="flex flex-col items-center bg-muted-foreground/10 text-muted-foreground rounded-xl p-2 scale-[0.8]">
        <div class="w-full text-center">
          {{ uniqueInCol.length }}
          <div class="text-xs font-semibold">UNIQUE</div>
        </div>
      </div>
      <span class="font-medium text-sm px-2">OF</span>
      <div v-if="props.title" class="text-xl flex">
        <span class="p-1 bg-muted-foreground/10 text-muted-foreground rounded-xl px-2">{{
          props.title }}</span>
      </div>
    </div>
    <div class="">
      <p v-if="props.title" class="text-xs pl-12 -mb-8">
        Values in <Badge
          class="bg-muted-foreground/10 text-muted-foreground text-xs py-0 px-1 font-normal">{{
          props.title }}</Badge>
      </p>

      <StatsPanelBar 
        v-if="shouldRenderBar"
        :cell="props.cell"
        :key="`${props.title}-${props.cell}`"
        :data="props.col"
      />

      <NameStatus v-if="props.col && (props?.metadata?.inferredColumnTitles?.[props.title] === 'botanicalname')"
        :data="props.col" :cell="props.cell" :metadata="metadata" />
    </div>
  </div>
</template>

<style scoped>

</style>

