import { defineStore } from 'pinia'

export const useValidationStore = defineStore(
  "ValidationStore",
  () => {
    const allValidations = ref([
      {
        name: 'nameStatus',
        result: {},
        show: false
      }
    ])

    const addValidation = (validation: any) => {
      const existingIndex = allValidations.value.findIndex(v => v.name === validation.name)
      if (existingIndex === -1) {
        allValidations.value.push(validation)
      } else {
        allValidations.value[existingIndex] = validation
      }
    }

    return {
        allValidations,
        addValidation
    }
    
  }, {
      persist:  true
  }
)