<script setup>

const {words, radius} = defineProps({
  words: { type: Array, required: true },
  radius: { type: Number, required: true }
})

const cloudContainer = ref(null)
const cloudInstance = ref(null)

const emit = defineEmits(['click'])

const options = {
  radius,
  maxSpeed: 'fast', // 滚动最大速度, 取值: slow, normal(默认), fast
  initSpeed: 'normal', // 滚动初速度, 取值: slow, normal(默认), fast
  direction: -135, // 初始滚动方向, 取值角度(顺时针deg): 0 对应 top, 90 对应 left, 135 对应 right-bottom(默认)...
  keep: false
}

onMounted(async () => {
  if (import.meta.client) {
    const JsTagCloud = (await import('js-tag-cloud')).default
    cloudInstance.value = JsTagCloud(cloudContainer.value, words, options)
    // Add click event listener to the cloud container
    cloudContainer.value.addEventListener('click', function clickEventHandler(e) {
      if (e.target.className === 'js-tag-cloud-item') {
        
      emit('click', e.target.innerText)
      }
    });
  }
})
</script>

<template>
  <ClientOnly>
    <div :class="$attrs.class" style="display:flex; justify-content:center; align-items:center;" id="tagCloud" ref="cloudContainer"></div>
  </ClientOnly>
</template>

<style>
.js-tag-cloud-item {
  cursor: pointer !important;
}
</style>