<script setup lang="ts">

const props = defineProps({
    panel: { type: Object, required: true },
    height: { type: [Number, String], required: false },
    panel_id: { type: String, required: true },
    toolLabel: { type: String, required: true },
    dataKey: { type: String, default: 'data' },
});

// Declare emits
const emit = defineEmits(['error', 'update:panel']);

const dockStore = useDockStore();
const { addPanelState } = dockStore; 
const { saving } = storeToRefs(dockStore);

const projectData = useProjectData();
const { setupPanelWatchers } = projectData;

const { tools } = useTools();

const data = ref(null)
const metadata = ref(null)

const tool_id = computed(() => tools.find(tool => tool.label === props.toolLabel)?.uid)

const updateState = setupPanelWatchers(props.panel, props.panel_id, tool_id, data, props.dataKey, saving, metadata)

watch(updateState, (newState) => {
    if (newState) {
        addPanelState(newState)
        emit('update:panel', newState)
    }
}, { deep: true })

// Handle error
const handleError = (error: any) => {
    emit('error', error)
}
</script>

<template>
    <slot
        :height="height"
        :data="data" 
        :tool_id="tool_id" 
        :metadata="metadata"
        :updateState="updateState"
        :onError="handleError"
    >
    {{ data }}
    </slot>
</template>