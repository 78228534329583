<script setup lang="ts">
import PanelComponent from './PanelComponent.vue'

const refreshedKey = ref(0);



const props = defineProps({
  panel: { type: Object, required: true },
  height: { type: [Number, String], required: false },
  panel_id: { type: String, required: true },
});



const handleWordClick = (word) => {
  console.log(word)
}

</script>

<template>
  <PanelComponent :panel="props.panel" :height="props.height" :panel_id="props.panel_id"
     toolLabel="Visualise" dataKey="species">
    <template #default="{ data, tool_id, updateState }">
      <keep-alive>
        <div class="overflow-hidden w-full h-full p-0 m-0 rounded-md p-2 pl-4 pb-10">
          <div class="flex justify-center align-center items-center w-full h-full">
            <!-- {{ props.panel?.data?.output.rawDocs?.[0].pageContent }} -->
            <!-- {{ props.panel?.data?.output.species }} -->
            <div v-if="props.panel">
              <WordCloud @click="handleWordClick" class="w-full h-full select-none" :words="props.panel?.data?.output.species" :radius="370" />
            </div>
            <div v-else>
              <span alt="prop panel is undefined">Oops something went wrong</span>
            </div>
          </div>
        </div>
      </keep-alive>
    </template>
  </PanelComponent>
</template>