<script setup lang="ts">
import 'vue3-photo-preview/dist/index.css';
import PanelComponent from './PanelComponent.vue'


const validationStore = useValidationStore()
const {allValidations} = storeToRefs(validationStore)

const props = defineProps({
  panel: { type: Object, required: true },
  height: { type: [Number, String], required: false },
  panel_id: { type: String, required: true },
});

useSquircleObserver()

const imgList = [
  'https://images.unsplash.com/photo-1725714354941-02986971c66b?q=80&w=2487&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D', 'https://images.unsplash.com/photo-1725714835781-5bd067b81a8c?q=80&w=2487&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D', 'https://images.unsplash.com/photo-1725940890793-d0ef6208b266?q=80&w=2340&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D', 'https://images.unsplash.com/photo-1725714834625-7773f48389db?q=80&w=2487&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'
]



</script>

<template>
  <PanelComponent :panel="props.panel" :height="props.height" :panel_id="props.panel_id" toolLabel="Gallery"
    dataKey="url">
    <template #default="{ data, tool_id, updateState }">
      <keep-alive>
        <div class="overflow-hidden w-full h-full p-0 m-0 rounded-md p-2 pl-4">
          <ScrollArea class="w-full h-full">  
          <div class="flex justify-center align-center items-center w-full h-full">
          <div v-if="props.panel">
            <!-- <img :src="props.panel?.data?.output.url" alt="gallery" class="w-full object-cover" /> -->
            <photo-provider :should-transition="true">
              <photo-consumer v-for="src in imgList" :intro="src" :key="src" :src="src">
                <img :src="src" class="view-box w-[10vw] h-[10vw] cursor-pointer  hover:shadow-lg transition-all duration-100 m-5 squircle-30">
              </photo-consumer>
            </photo-provider>

          </div>
          <div v-else>
            <span alt="prop panel is undefined">Oops something went wrong</span>
            </div>
          </div>
          </ScrollArea>
          </div>
      </keep-alive>
    </template>
  </PanelComponent>
</template>