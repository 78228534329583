<script setup lang="ts">
import type { VChart } from '#components'
import { use } from 'echarts/core'
import { ToolboxComponent } from 'echarts/components'
import { BarChart } from 'echarts/charts'
import { graphic } from 'echarts'

use([ToolboxComponent, BarChart])

const props = defineProps<{
    data: (number | string)[] | null | undefined
    cell: number | string
}>()

const chart = ref<InstanceType<typeof VChart> | null>(null)
const chartContainer = ref(null)

const initOptions = computed(() => ({
    renderer: 'svg'
}))

const option = computed(() => {
    const histogramData = createHistogram(props.data || [])

    if (!histogramData) {
        return null
    }

    const bins = histogramData.bins
    const labelThreshold = 20 // Show all labels if total bins are less than this

    const showAllLabels = bins.length <= labelThreshold

    return {
        animationDuration: 0, // Set animation duration to 180ms
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'shadow'
            },
            formatter: (params: any) => {
                const bin = histogramData.bins[params[0].dataIndex]
                return `${bin.label}`
            }
        },
        grid: {
            left: '10%',
            right: '5%',
            bottom: '15%',
            containLabel: true
        },
        xAxis: {
            type: 'category',
            data: bins.map(bin => bin.label),
            axisLabel: {
                rotate: 45,
                interval: showAllLabels ? 0 : 1, // Show every label if below threshold, otherwise every second label
                formatter: (value: string, index: number) => {
                    if (showAllLabels || index % 2 === 0) {
                        return value
                    }
                    return ''
                },
                color: 'hsl(var(--muted-foreground))',
                overflow: 'breakAll',
                align: 'right',
                verticalAlign: 'middle'
            },
            axisLine: { // Add this object
                lineStyle: {
                    color: 'hsl(var(--muted))'
                }
            },
            splitLine: { // Add this object
                lineStyle: {
                    color: 'hsl(var(--muted))'
                }
            }
        },
        yAxis: {
            type: 'value',
            name: '',
            nameLocation: 'middle',
            nameGap: 50,
            axisLabel: { // Add this object
                color: 'hsl(var(--muted-foreground))'
            },
            axisLine: { // Add this object
                lineStyle: {
                    color: 'hsl(var(--muted))'
                }
            },
            splitLine: { // Add this object
                lineStyle: {
                    color: 'hsl(var(--muted))'
                }
            }
        },
        series: [
            {
                name: 'Histogram',
                type: 'bar',
                data: bins.map(bin => ({
                    value: bin.count,
                    itemStyle: {
                        color: String(bin.label) === String(props.cell)
                            ? 'hsl(var(--accent))' // Highlight color for matching cell
                            : 'hsl(var(--muted-foreground))' // Default color
                    }
                })),
                emphasis: {
                    itemStyle: {
                        color: 'hsl(var(--accent))'
                    }
                }
            }
        ],
        textStyle: { // Add this object
            color: 'hsl(var(--primary))'
        }
    }
})

function createHistogram(data: (number | string)[]): { bins: { label: string, count: number }[], counts: number[] } | null {
    if (!data || data.length === 0) {
        return null
    }

    const countMap = new Map<string, number>()

    // Filter out empty values and count non-empty ones
    data.filter(value => value !== '' && value !== null && value !== undefined)
        .forEach(value => {
            const key = String(value)
            countMap.set(key, (countMap.get(key) || 0) + 1)
        })

    // Sort bins in descending order by count
    const bins = Array.from(countMap.entries())
        .map(([label, count]) => ({ label, count }))
        .sort((a, b) => b.count - a.count)

    const counts = bins.map(bin => bin.count)

    return { bins, counts }
}

const hasValidData = computed(() => {
    return props.data && props.data.length > 0 && option.value !== null
})
</script>

<template>
    <div ref="chartContainer" class="chart-container p-0 m-0">
        <VChart v-if="hasValidData" ref="chart" :option="option" :init-options="initOptions" :autoresize="true" />
        <div v-else class="no-data-message">No valid data available for visualization</div>
    </div>
</template>

<style scoped>
.chart-container {
    width: 100%;
    height: 400px;
    /* min-width: 300px; */
    min-height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.no-data-message {
    font-size: 16px;
    color: hsl(var(--primary));
}
</style>